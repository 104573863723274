import React, { useState, useEffect } from "react";
import Section from "../components/Section";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import video1 from "../assets/images/aboutUs/about-us-video-1.mp4";
import image1 from "../assets/images/aboutUs/team-photo.jpg";
import video2 from "../assets/images/aboutUs/manufacturing.mp4";
import overlayImage1 from "../assets/images/aboutUs/overlay1.png";
import qualityControl from "../assets/images/aboutUs/quality-control.mp4";
import perfectFinishing from "../assets/images/aboutUs/perfect-finishing.mp4";
import assemblyline from "../assets/images/aboutUs/assembly-line.mp4";
import mrAtilla from "../assets/images/aboutUs/winton-mr-atilla.jpg";
import farabiBey from "../assets/images/aboutUs/farabi-bey.jpg";
import team from "../assets/images/aboutUs/team-photo.jpg";
import teamInside from "../assets/images/aboutUs/team-photo-inside.jpg";

function AboutUs() {
  const [currentSection, setCurrentSection] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);

  const SampleNextArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="arrow next-arrow" onClick={onClick}>
        &#10095;
      </div>
    );
  };

  const SamplePrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="arrow prev-arrow" onClick={onClick}>
        &#10094;
      </div>
    );
  };

  const sections = [
    {
      title: "About Us",
      subtitle: "",
      backgroundVideo: video1,
      isFirstSection: false,
      customContent: (
        <div className="info-cards">
          <div className="info-card">
            <h3 className="info-value">7 ha</h3>
            <p className="info-label">Factory Space</p>
          </div>
          <div className="info-card">
            <h3 className="info-value">4</h3>
            <p className="info-label">Integrated Facilities</p>
          </div>
          <div className="info-card">
            <h3 className="info-value">720+</h3>
            <p className="info-label">Vehicles per Year</p>
          </div>
        </div>
      ),
    },
    {
      isFirstSection: false,
      customContent: (
        <div className="manufacturing-content">
          <div className="video-container">
            <video
              src={video2}
              autoPlay
              loop
              muted
              alt="Manufacturing"
              className="section-video"
            />
          </div>
          <div className="text-columns">
            <h2 className="section-heading">Manufacturing</h2>
            <p className="section-paragraph">
              Our vehicles undergo a sophisticated journey of designing,
              engineering, and assembling, meticulously crafted to meet the
              highest standards of safety, durability, and efficiency.
            </p>
          </div>
        </div>
      ),
    },
    {
      isFirstSection: false,
      customContent: (
        <div className="video-grid-section">
          <img src={overlayImage1} alt="Overlay" className="section-overlay" />
          {/* İlk video ve metin bloğu */}
          <div className="grid-video-item grid-video-left">
            <div className="grid-video-container">
              <video
                src={video1}
                autoPlay
                loop
                muted
                className="grid-section-video"
              />
            </div>
            <div className="grid-text-container">
              <h3 className="grid-section-heading">Body Builder</h3>
              <p className="grid-section-paragraph">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                diam nonummy nibh euismod tincidunt ut laoreet dolore magna
                aliquam erat volutpat.
              </p>
            </div>
          </div>

          {/* İkinci video ve metin bloğu */}
          <div className="grid-video-item grid-video-right">
            <div className="grid-text-container">
              <h3 className="grid-section-heading">Automation Welding</h3>
              <p className="grid-section-paragraph">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                diam nonummy nibh euismod tincidunt ut laoreet dolore magna
                aliquam erat volutpat.
              </p>
            </div>
            <div className="grid-video-container">
              <video
                src={video2}
                autoPlay
                loop
                muted
                className="grid-section-video"
              />
            </div>
          </div>
        </div>
      ),
    },
    {
      isFirstSection: false,
      customContent: (
        <div className="manufacturing-content">
          <div className="video-container">
            <video
              src={qualityControl}
              autoPlay
              loop
              muted
              alt="Quality Control"
              className="section-video"
            />
          </div>
          <div className="text-columns">
            <h2 className="section-heading">Quality Control</h2>
            <p className="section-paragraph">
              We manufacture our silo trailers in accordance with EN 13445 and
              PED 2014/68/EU standards, and our semi-trailer fuel tankers comply
              with EN 13094 and ADR regulations.
            </p>
          </div>
        </div>
      ),
    },
    {
      isFirstSection: false,
      customContent: (
        <div className="manufacturing-content">
          <div className="video-container">
            <video
              src={perfectFinishing}
              autoPlay
              loop
              muted
              alt="The Perfect Finishing"
              className="section-video"
            />
          </div>
          <div className="text-columns">
            <h2 className="section-heading">
              The Perfect <br />
              Finishing
            </h2>
            <p className="section-paragraph">
              Our facility boasts 10 painting booths distributed across 2 lines,
              each running 60 meters long, equipped with digital environmental
              monitoring systems and ex-proof man lifts for a perfect finishing.
            </p>
          </div>
        </div>
      ),
    },
    {
      isFirstSection: false,
      customContent: (
        <div className="grid-container">
          <div className="grid-image">
            <img src={image1} alt="Foto" className="image-left" />
          </div>
          <div className="grid-text grid-text-right">
            We supply all the materials we use in the process from a global
            industry leader.
          </div>
          <div className="grid-text grid-text-left">
            The vehicles first receive meticulous surface preparation, followed
            by layers of primary coat, acrylic primer and a final acrylic top
            coat, each step enhancing their finish.
          </div>
          <div className="grid-image">
            <img src={image1} alt="Foto" className="image-right" />
          </div>
        </div>
      ),
    },
    {
      isFirstSection: false,
      customContent: (
        <div className="manufacturing-content">
          <div className="video-container">
            <video
              src={assemblyline}
              autoPlay
              loop
              muted
              alt="Assembly Line"
              className="section-video"
            />
          </div>
          <div className="text-columns">
            <h2 className="section-heading">Assembly Line</h2>
            <p className="section-paragraph">
              Our tailor-made vehicles are delivered to clients after being
              assembled with impeccable craftsmanship. Each vehicle undergoes
              rigorous testing to ensure it meets the highest standards of
              quality and performance.
            </p>
          </div>
        </div>
      ),
    },
    /*
    {
      isFirstSection: false,
      customContent: (
        <div className="manufacturing-content">
          <div className="carousel-container">
            <Slider
              dots={true}
              infinite={true}
              speed={1300}
              slidesToShow={1}
              slidesToScroll={1}
              autoplay={true}
              autoplaySpeed={3000}
              nextArrow={<SampleNextArrow />}
              prevArrow={<SamplePrevArrow />}
            >
              <div>
                <img src={mrAtilla} alt="Slide 1" className="carousel-image" />
              </div>
              <div>
                <img src={team} alt="Slide 2" className="carousel-image" />
              </div>
              <div>
                <img
                  src={teamInside}
                  alt="Slide 3"
                  className="carousel-image"
                />
              </div>
              <div>
                <img src={farabiBey} alt="Slide 4" className="carousel-image" />
              </div>
            </Slider>
          </div>
          <div className="text-columns">
            <h2 className="section-heading">
              &nbsp;&nbsp;&nbsp;The Winton Team
            </h2>
            <p className="section-paragraph">
              Join us and become part of our vibrant team, where collaboration
              and a shared commitment to teamwork define our success. You’ll
              find opportunities for growth and development in an environment
              that values your contributions.
            </p>
          </div>
        </div>
      ),
    },*/
  ];

  const handleScroll = (e) => {
    if (isScrolling) return;

    const direction = e.deltaY > 0 ? 1 : -1;
    setCurrentSection((prevSection) =>
      Math.max(0, Math.min(prevSection + direction, sections.length - 1))
    );

    setIsScrolling(true);
    setTimeout(() => setIsScrolling(false), 500);
  };

  useEffect(() => {
    window.addEventListener("wheel", handleScroll);
    return () => window.removeEventListener("wheel", handleScroll);
  }, [isScrolling]);

  return (
    <div className="page" style={{ overflow: "hidden", height: "100vh" }}>
      {sections.map((section, index) => (
        <div
          key={index}
          style={{
            display: index === currentSection ? "block" : "none",
            height: index === 0 ? "100vh" : "calc(100vh - 60px)",
          }}
        >
          <Section
            title={section.title}
            subtitle={section.subtitle}
            background={section.background}
            isFirstSection={section.isFirstSection || false}
            button1Text={section.button1Text}
            button2Text={section.button2Text}
            backgroundVideo={section.backgroundVideo}
            customContent={section.customContent} // Özel içerik olarak geçiyoruz
          />
        </div>
      ))}
    </div>
  );
}

export default AboutUs;
