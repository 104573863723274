import React, { useState, useEffect, useRef } from "react";

function Section({
  title,
  subtitle,
  background,
  backgroundVideo,
  isFirstSection,
  button1Text,
  button2Text,
  customContent,
}) {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      },
      { threshold: 0.5 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <section
      ref={sectionRef}
      className={`section ${isVisible ? "fade-in" : "fade-out"} ${
        isFirstSection ? "first-section" : "second-section"
      }`}
      style={{
        backgroundImage:
          background && !backgroundVideo ? `url(${background})` : "none",
        backgroundSize: "cover",
        backgroundPosition: "center -60px",
        backgroundColor:
          background || backgroundVideo ? "transparent" : "black",
      }}
    >
      {/* Video Arka Planı */}
      {backgroundVideo && (
        <video
          className="background-video"
          src={backgroundVideo}
          autoPlay
          loop
          muted
        />
      )}

      <div className="section-content">
        {/* Başlık Grubu: Sadece title veya subtitle varsa render edilir */}
        {(title || subtitle) && (
          <div className="title-group">
            {subtitle && <h2 className="section-subtitle">{subtitle}</h2>}
            {title && <h1 className="section-title">{title}</h1>}
          </div>
        )}

        {/* Özel HTML içeriği varsa burada render edilir */}
        {customContent && <div className="custom-content">{customContent}</div>}

        {(button1Text || button2Text) && (
          <div className="section-buttons">
            {button1Text && (
              <button className="button-primary">{button1Text}</button>
            )}
            {button2Text && (
              <button className="button-secondary">{button2Text}</button>
            )}
          </div>
        )}
      </div>

      {isFirstSection && (
        <div className="scroll-indicator">
          <div className="mouse-icon"></div>
          <p>Scroll Down</p>
        </div>
      )}
    </section>
  );
}

export default Section;
