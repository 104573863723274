import React, { useState, useEffect } from "react";
import Section from "../components/Section";
import videoBanner from "../assets/images/petroleumGoods/PetroleumBanner.mp4";

function PetroleumGoods() {
  const [currentSection, setCurrentSection] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);
  const sections = [
    {
      title: "Petroleum Goods",
      subtitle: "",
      backgroundVideo: videoBanner,
      isFirstSection: false,
    },
  ];
  const handleScroll = (e) => {
    if (isScrolling) return;

    const direction = e.deltaY > 0 ? 1 : -1;
    setCurrentSection((prevSection) =>
      Math.max(0, Math.min(prevSection + direction, sections.length - 1))
    );

    setIsScrolling(true);
    setTimeout(() => setIsScrolling(false), 500);
  };

  useEffect(() => {
    window.addEventListener("wheel", handleScroll);
    return () => window.removeEventListener("wheel", handleScroll);
  }, [isScrolling]);

  return (
    <div className="page" style={{ overflow: "hidden", height: "100vh" }}>
      {sections.map((section, index) => (
        <div
          key={index}
          style={{
            display: index === currentSection ? "block" : "none",
            height: index === 0 ? "100vh" : "calc(100vh - 60px)",
          }}
        >
          <Section
            title={section.title}
            subtitle={section.subtitle}
            background={section.background}
            isFirstSection={section.isFirstSection || false}
            button1Text={section.button1Text}
            button2Text={section.button2Text}
            backgroundVideo={section.backgroundVideo}
            customContent={section.customContent} // Özel içerik olarak geçiyoruz
          />
        </div>
      ))}
    </div>
  );
}
export default PetroleumGoods;
