// src/pages/Anasayfa.js

import React, { useState, useEffect } from "react";
import Section from "../components/Section";
import Navbar from "../components/Navbar";
import foodstuffImg from "../assets/images/home/foodstuff.jpg";
import tippingImg from "../assets/images/home/tipping-silo.jpg";
import petroleumImg from "../assets/images/home/petroleum.jpg";
import industrialImg from "../assets/images/home/silo-industrial.jpg";
import hVideo from "../assets/home.mp4";

function Anasayfa() {
  const [currentSection, setCurrentSection] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);

  // Her section için içeriği ve görsel bilgileri içeren dizi
  const sections = [
    {
      background: "black",
      isFirstSection: true,
      backgroundVideo: hVideo,
    },
    {
      title: "Foodstuffs",
      subtitle:
        "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0Silo Trailer for\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0",
      background: foodstuffImg,
      button1Text: "Get in Touch",
      button2Text: "Learn More",
    },
    {
      title: "Industrial Granules",
      subtitle: "Silo Trailer for",
      background: industrialImg,
      button1Text: "Get in Touch",
      button2Text: "Learn More",
    },
    {
      title: "Tipping Silo Trailer",
      subtitle: "",
      background: tippingImg,
      button1Text: "Get in Touch",
      button2Text: "Learn More",
    },
    {
      title: "Petroleum Goods",
      subtitle: "Semi Trailer Tanker For",
      background: petroleumImg,
      button1Text: "Get in Touch",
      button2Text: "Learn More",
    },
  ];

  // Kaydırma hareketini yönetir
  const handleScroll = (e) => {
    if (isScrolling) return;

    const direction = e.deltaY > 0 ? 1 : -1;
    setCurrentSection((prevSection) =>
      Math.max(0, Math.min(prevSection + direction, sections.length - 1))
    );

    setIsScrolling(true);
    setTimeout(() => setIsScrolling(false), 500);
  };

  useEffect(() => {
    // Kaydırma olayını ekle
    window.addEventListener("wheel", handleScroll);
    return () => window.removeEventListener("wheel", handleScroll);
  }, [isScrolling]);

  return (
    <div className="page" style={{ overflow: "hidden", height: "100vh" }}>
      {sections.map((section, index) => (
        <div
          key={index}
          className={index === 0 ? "first-section" : ""}
          style={{
            display: index === currentSection ? "block" : "none",
            height: index === 0 ? "100vh" : "calc(100vh - 60px)",
          }}
        >
          <Section
            title={section.title}
            subtitle={section.subtitle}
            background={section.background}
            backgroundVideo={section.backgroundVideo}
            isFirstSection={section.isFirstSection || false}
            button1Text={section.button1Text}
            button2Text={section.button2Text}
          />
        </div>
      ))}
    </div>
  );
}

export default Anasayfa;
