// src/App.js

import React, { useEffect, useRef } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./styles/main.scss";
import Anasayfa from "./pages/Anasayfa";
import Foodstuff from "./pages/Foodstuff";
import AboutUs from "./pages/AboutUs";
import PetroleumGoods from "./pages/PetroleumGoods";
import TippingSiloTrailer from "./pages/TippingSiloTrailer";
import IndustrialGranules from "./pages/IndustrialGranules";
import Navbar from "./components/Navbar";

function App() {
  const sectionRefs = useRef([]); // Tüm sectionları takip edecek bir dizi
  const currentSection = useRef(0); // Şu anda hangi section'da olduğunu takip eden bir değişken

  useEffect(() => {
    let isScrolling = false;

    const handleWheel = (event) => {
      if (isScrolling) return; // Halen bir kaydırma işlemi devam ediyorsa yeni kaydırmayı engeller
      isScrolling = true;

      // Kaydırma yönünü belirle (yukarı veya aşağı)
      const direction = event.deltaY > 0 ? 1 : -1;
      const nextSectionIndex = currentSection.current + direction;

      // Eğer sonraki section geçerli bir index değilse işlem yapma
      if (
        nextSectionIndex < 0 ||
        nextSectionIndex >= sectionRefs.current.length
      ) {
        isScrolling = false;
        return;
      }

      // Belirli bir section'a yumuşak kaydırma yap
      sectionRefs.current[nextSectionIndex].scrollIntoView({
        behavior: "smooth",
      });

      // Geçerli section'u güncelle
      currentSection.current = nextSectionIndex;

      // Kaydırma işlemini belirli bir süre için engelle
      setTimeout(() => {
        isScrolling = false;
      }, 800); // 0.8 saniye kaydırmalar arasındaki duraklama süresi

      event.preventDefault();
    };

    // Mouse wheel olayını ekler
    window.addEventListener("wheel", handleWheel, { passive: false });

    return () => {
      window.removeEventListener("wheel", handleWheel); // Olay dinleyicisini temizler
    };
  }, []);

  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<Anasayfa />} />
          <Route path="/foodstuff" element={<Foodstuff />} />
          <Route
            path="/about-us"
            element={<AboutUs />} // About Us rotasını buraya ekleyin
          />
          <Route path="/petroleum-goods" element={<PetroleumGoods />} />
          <Route
            path="/tipping-silo-trailer"
            element={<TippingSiloTrailer />}
          />
          <Route path="/industrial-granules" element={<IndustrialGranules />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
