// src/components/Navbar.js

import React, { useState, useEffect, useRef } from "react";
import logo from "../assets/images/navbar/winton-logo.png";
import logoRed from "../assets/images/navbar/winton-logo-red.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe, faBars } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import vehicleLogo1 from "../assets/images/navbar/foodstuff.png";
import vehicleLogo2 from "../assets/images/navbar/industrial.png";
import vehicleLogo3 from "../assets/images/navbar/tipping.png";
import vehicleLogo4 from "../assets/images/navbar/petroleum.png";

function Navbar() {
  const [isVehiclesOpen, setIsVehiclesOpen] = useState(false);
  const [isDiscoverOpen, setIsDiscoverOpen] = useState(false);
  const vehiclesDropdownRef = useRef(null);
  const discoverDropdownRef = useRef(null);

  const toggleVehiclesMenu = () => {
    setIsVehiclesOpen(!isVehiclesOpen);
    setIsDiscoverOpen(false); // Diğer dropdown kapalı kalır
  };

  const toggleDiscoverMenu = () => {
    setIsDiscoverOpen(!isDiscoverOpen);
    setIsVehiclesOpen(false); // Diğer dropdown kapalı kalır
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        vehiclesDropdownRef.current &&
        !vehiclesDropdownRef.current.contains(event.target)
      ) {
        setIsVehiclesOpen(false);
      }
      if (
        discoverDropdownRef.current &&
        !discoverDropdownRef.current.contains(event.target)
      ) {
        setIsDiscoverOpen(false);
      }
    };

    if (isVehiclesOpen || isDiscoverOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isVehiclesOpen, isDiscoverOpen]);

  return (
    <nav
      className={`navbar ${
        isVehiclesOpen || isDiscoverOpen ? "navbar-white" : ""
      }`}
    >
      <Link to="/" className="navbar-logo">
        <img
          src={isVehiclesOpen || isDiscoverOpen ? logoRed : logo}
          alt="Winton Logo"
        />
      </Link>

      <ul className="navbar-links">
        <li
          onClick={toggleVehiclesMenu}
          className={`nav-item ${isVehiclesOpen ? "active" : ""}`}
        >
          Vehicles
        </li>
        <li
          onClick={toggleDiscoverMenu}
          className={`nav-item ${isDiscoverOpen ? "active" : ""}`}
        >
          Discover
        </li>
        <li className="nav-item">
          Railway Systems
          <span className="new-label">NEW</span>
        </li>
      </ul>

      <div className="navbar-language">
        <FontAwesomeIcon icon={faGlobe} />
      </div>

      <div className="hamburger" onClick={toggleVehiclesMenu}>
        <FontAwesomeIcon icon={faBars} />
      </div>

      {/* Vehicles Dropdown Menü */}
      {isVehiclesOpen && (
        <div className="vehicles-dropdown" ref={vehiclesDropdownRef}>
          <Link to="/foodstuff" className="vehicle-item no-underline">
            <img
              src={vehicleLogo1}
              alt="Silo Vehicles for Foodstuff"
              className="vehicle-logo"
            />
            <p className="vehicle-name">Silo Vehicles for Foodstuff</p>
          </Link>
          <Link to="/industrial-granules" className="vehicle-item no-underline">
            <img
              src={vehicleLogo2}
              alt="Silo Vehicles for Industrial Granules"
              className="vehicle-logo"
            />
            <p className="vehicle-name">
              Silo Vehicles for Industrial Granules
            </p>
          </Link>
          <Link
            to="/tipping-silo-trailer"
            className="vehicle-item no-underline"
          >
            <img
              src={vehicleLogo3}
              alt="Tipping Silo Trailer"
              className="vehicle-logo"
            />
            <p className="vehicle-name">Tipping Silo Trailer</p>
          </Link>
          <Link to="/petroleum-goods" className="vehicle-item no-underline">
            <img
              src={vehicleLogo4}
              alt="For Petroleum"
              className="vehicle-logo"
            />
            <p className="vehicle-name">For Petroleum</p>
          </Link>
        </div>
      )}

      {/* Discover Dropdown Menü */}
      {isDiscoverOpen && (
        <div className="discover-dropdown" ref={discoverDropdownRef}>
          <p className="discover-item">
            The <br /> Company
          </p>
          <Link to="/about-us" className="discover-item no-underline">
            About Us
          </Link>
          <p className="discover-item">
            Service <br /> Spare Parts
          </p>
        </div>
      )}
    </nav>
  );
}

export default Navbar;
